ACC.common = {
	currentCurrency: $("main").data('currencyIsoCode') || "USD",
	processingMessage: $("<img>").attr("src", ACC.config.commonResourcePath + "/images/spinner.gif"),


	blockFormAndShowProcessingMessage: function (submitButton)
	{
		var form = submitButton.parents('form:first');
		form.block({ message: ACC.common.processingMessage });
	},

	refreshScreenReaderBuffer: function ()
	{
		// changes a value in a hidden form field in order
		// to trigger a buffer update in a screen reader
		$('#accesibility_refreshScreenReaderBufferField').attr('value', new Date().getTime());
	},

	checkAuthenticationStatusBeforeAction: function (actionCallback)
	{
		$.ajax({
			url: ACC.config.authenticationStatusUrl,
			statusCode: {
				401: function () {
					location.href = ACC.config.loginUrl;
				}
			},
			dataType: 'json',
			success: function (data) {
				if (data == "authenticated") {
					actionCallback();
				}
			}
		});
	},

	encodeHtml: function (rawText)
	{
		return rawText.toString()
				.replace(/&/g, '&amp;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.replace(/"/g, '&quot;')
				.replace(/'/g, '&#39;')
				.replace(/\//g, '&#47;');
	}
};

// Function to load JS Scripts on demand but once
var loadedScriptsArray = [];
function loadScriptOnDemand(url, callback) {
	if (loadedScriptsArray.indexOf(url) === -1){
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;
		script.async = true;
		script.onload = function () {
			loadedScriptsArray.push(url);
			if (typeof callback === 'function') {
				callback();
			}
		};
		script.onerror = function () {
			console.log("Error in loading script: " + url);
		};
		document.body.appendChild(script);
	}
}
// Console log only for specific environments
function consoleLog(val) {
    let envArray = ['localhost', 'dev3-', 'dev4-', 'dev5-', 'dev6-', 'qa-', 'qa2-'];
    if(envArray.some(site => window.location.href.indexOf(site) !== -1)) {
        console.log(val);
        return;
    }
    return;
}

/* Extend jquery with a postJSON method */
jQuery.extend({
	postJSON: function (url, data, callback)
	{
		return jQuery.post(url, data, callback, "json");
	}
});

// add a CSRF request token to POST ajax request if its not available
$.ajaxPrefilter(function (options, originalOptions, jqXHR)
{
	// Modify options, control originalOptions, store jqXHR, etc
	
	if ((options.type === "post" || options.type === "POST") && !options.crossDomain)
	{
		var noData = (typeof options.data === "undefined");
		
		if (!noData && options.data.indexOf("CSRFToken") === -1)
		{
			var obj1 = JSON.parse(options.data);
			var obj2 = {"CSRFToken" : ACC.config.CSRFToken};
			$.extend(true, obj1, obj2);
			options.data = JSON.stringify(obj1);
		}
		
	}
});
if ($(window).width() <= 991){
	$(".disclaimer").click(function() {
	$("#footer-bottom-content").toggle();
		if ($("#footer-bottom-content").is(':visible')){
		  window.location.hash = "#racDisclaimer";
		}
	$(this).find('span').toggleClass('caret-arrow-down caret-arrow-up');
	});
}
$(window).on('load', function(){
    if(isTrue($('#loadMedalliaFeedbackScript').attr('data-value'))) {
        setTimeout(function () {
            loadScriptOnDemand($('#medalliaFeedbackUrl').attr('data-value'));
        }, Number($('#loadMedalliaScriptsDelaySeconds').attr('data-value')));
    }
    if (window.location.href.indexOf("agreements") > -1) {
        $(".rac-greenContainer.homepage-miniButterBar").hide();
    }
    else {
        $('.homepage-miniButterBar').show();
    }
    // Load Chat Scripts
	let chatCookiePlacement = getCookieByName('sf_chat_initiate');
    let byPassChatBotCookie = $('#byPassChatBotCookie').attr('data-value');
    let isChatBotEnabled = getCookieByName('enableChatBot');
    if (isTrue($('#isChatFeatureEnabled').attr('data-value'))) {
        setTimeout(function() {
            loadScriptOnDemand($('#chatScriptsDexieUrl').attr('data-value'), function () {
                if(isTrue($('#isWebpackEnabled').val())) {
                    loadCSS($('#chatComponentCssUrl').attr('data-value'), '', 'all');
					if (chatCookiePlacement === 'bot') {
						loadScriptOnDemand($('#chatBotJsUrl').attr('data-value'));
					} else if ((window.location.href.indexOf('/myaccount/') > -1 && chatCookiePlacement != 'sales')
		                || chatCookiePlacement == 'myaccount') {
		                loadScriptOnDemand($('#sfChatJsUrl').attr('data-value'));
	                } else {
		                if(byPassChatBotCookie === 'yes' || (!isNullUndefined(isChatBotEnabled) && isTrue(isChatBotEnabled))) {
			                loadScriptOnDemand($('#chatBotJsUrl').attr('data-value'));
		                } else {
			                loadScriptOnDemand($('#sfChatJsUrl').attr('data-value'));
		                }
	                }
                } else {
                    loadCSS(`${ACC.config.themeResourcePath}/css/chat-component.css`, '', 'all');
                    loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/sf_data_send.js');
                    loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/sf_Data_Collector.js');
	                if (chatCookiePlacement === 'bot') {
		                loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/chatbot_init.js');
	                } else if ((window.location.href.indexOf('/myaccount/') > -1 && chatCookiePlacement != 'sales') || chatCookiePlacement == 'myaccount') {
		                loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/sf_Chat_Init_ESW.js');
		                loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/sf_Global_Code.js');
	                } else {
		                if (byPassChatBotCookie === 'yes' || (!isNullUndefined(isChatBotEnabled) && isTrue(isChatBotEnabled))) {
			                loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/chatbot_init.js');
		                } else {
			                loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/sf_Chat_Init_ESW.js');
			                loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/sf_Global_Code.js');
		                }
	                }
                    loadScriptOnDemand('/_ui/responsive/common/js/chatScripts/sf_chat_show_button_WOF_and_PDP.js');
                }
            });
        }, Number($('#chatScriptsLoadingDelay').attr('data-value')));
    }
});

$(document).ready(function() {
    // Restrict Chat button on specific pages by PageType or URL Identifier
    let restrictionsPageTypeArray = $('#chatScriptsPageTypeRestrictions').attr('data-value').split(',');
    let restrictionsUrlArray = $('#chatScriptsPageUrlRestrictions').attr('data-value').split(',');
    if (restrictionsPageTypeArray.some(type => RAC.coreDataLayer.pageType === type) ||
        restrictionsUrlArray.some(url => window.location.href.indexOf(url) !== -1)) {
        window.restrictChat = true;
    } else {
        window.restrictChat = false;
    }

	$(".rac-greenContainer.homepage-minibar").show();

	$(".greenContainerDesktop .fa-times").click(function() {
		$(".greenContainerDesktop").hide();
	});
	$(".rac-greenContainer.homepage-minibar .fa-times").click(function() {
		$(".rac-greenContainer.homepage-minibar").hide();
	});
	$(".rac-greenContainer.homepage-miniButterBar .fa-times").click(function() {
		$.ajax({
			url: '/hideButterBar',
			error: function(error) {
				console.log(error);
			}
		});
		$(".rac-greenContainer.homepage-miniButterBar").hide();
		$(".rac-greenContainer.homepage-minibar").show();
	});
	if ( ($('.header-store-locator').length === 0) || ($('.header-store-locator').hasClass("hide") )) {
		$(".homepage-miniButterBar").addClass("marginT23");
	}
    if (RAC.cordovaWrapper) {
		$('.delete-account-footer').removeClass('hide');
	}
});

$(document).ready(function() {
    $("#owl-demoFeaturedProducts, #owl-demoNews").owlCarousel({
        autoPlay : false,
        rewindNav : false,// Set AutoPlay to 3
                            // seconds

        items : 4,

        navigation : true,
        lazyLoad:true,
        responsive : {
            0 : {
                items : 1,
                loop : ($(this).children('.item').length > 1)
            },
            768 : {
                items : 2,
                loop : ($(this).children('.item').length > 2)
            },
            1000 : {
                items : 4,
                loop : ($(this).children('.item').length > 4)
            }
        }
    });
    if ($(window).width() <= 768) {
        $("#owl-demoFeaturedProducts .item:nth-child(-n+1), #owl-demoNews .item:nth-child(-n+1)")
                .css("display", "block");
    }
    if (($(window).width() == 769) && ($(window).width() < 1200)) {
        $("#owl-demoFeaturedProducts .item:nth-child(-n+3),  #owl-demoNews .item:nth-child(-n+3)")
                .css("display", "block");
    }

    $(".product-interest-wrapper").css("background", "none");
    $("#owl-demoFeaturedProducts, #owl-demoNews").css("visibility", "visible");
    $("#owl-demoFeaturedProducts .item:nth-child(-n+4), #owl-demoNews .item:nth-child(-n+4)").css(
            "display", "block");

    $(".news-wrapper").css("background", "none");

    $(".owl-next")
            .html('<img class="owl-carouselArrowsRight" height="29" width="29" alt="carouselRightArrow" src="/_ui/responsive/common/images/RAC-iconImages/Nav-arrow-right.svg">');
    $(".owl-prev")
            .html('<img class="owl-carouselArrowsLeft" height="29" width="29" alt="carouselLeftArrow" src="/_ui/responsive/common/images/RAC-iconImages/Nav-arrow-left.svg">');
});

$(".mobile-sort").click(function() {
	$("html, body").addClass("stop-page-load");
});

$(document).on('click', '.modalclose.link-btn',function() {
	$("html, body").removeClass("stop-page-load");
});

var menu_status = 0;
$(".hamburgerNav, #hideMainMenu").click(function() {
    loadScriptOnDemand('/_ui/responsive/common/js/home/mobileMenu.js');
    if (menu_status == 0) {
        $('body').addClass('posFixed');
        $('.submenu_dropdown').fadeToggle(300);
        $('.ham_menu').toggle("slide", {direction: "left" }, 300);
        $('#hideMainMenu').css('min-height', $('.ham_menu').height());
        $(".first-category-label").addClass("paddingL15");
        menu_status = 1;
        $(".modal-backdrop").addClass("menu-top");
        $(".navigationbarcollectioncomponent").show();
        $(".header-wrapper").addClass('menu-opened');
        if ($(window).width() <= 991){
            $(".hamburgerNav-Icon-desktop").addClass("hide").removeClass('show');
            $(".hamburgerNav-Icon-desktop").addClass("hide");
            $(".hamburgerNav-Icon-mobile").addClass('hide').removeClass('show');
            $(".hamburgerNav-Icon-Close").addClass('show');
        }else{
            $(".hamburgerNav-Icon-mobile").addClass("hide").removeClass('show');
            $(".hamburgerNav-Icon-desktop").addClass("hide").removeClass('show');
            $(".hamburgerNav-Icon-Close").addClass('show').removeClass('hide');
            $('.menu-hover-desktop').addClass("hide").removeClass('show');
        }
        $("#close").show();
        $("#menu").hide();
        $(".hamburgerNav-Icon-Close").addClass("show");
        $(".hamburgerNav").css("background-color", "white");
        $(".hamburger").css("color", "#0f99d6");
        $("#close").css("color", "#0f99d6");
        if (!isMobileDevice()) {
            if ($('.submenu_dropdown ul.ham_first').height() < 750) {
                $('.firstParent.menu-lang-toggler').addClass('language-li-reset');
            } else {
                $('.firstParent.menu-lang-toggler').removeClass('language-li-reset');
            }
        }
    } else if (menu_status == 1) {
        $(".header-wrapper").removeClass('menu-opened');
		if (isElemExist($(".my-offers")) && !$(".my-offers").hasClass('active')) {
			$('body').removeClass('posFixed');
		} else if (!isElemExist($(".my-offers"))) {
			$('body').removeClass('posFixed');
		}
        $('.ham_menu').toggle("slide", {direction: "left" }, 300);
        $('.submenu_dropdown').fadeToggle(300);
        menu_status = 0;
        if ($(window).width() <= 991){
            $(".hamburgerNav-Icon-mobile").removeClass('hide').addClass("show");
            $(".hamburgerNav-Icon-desktop").addClass("hide");
            $(".hamburgerNav-Icon-Close").removeClass('show').addClass('hide');
        }else{
            $(".hamburgerNav-Icon-desktop").removeClass('hide').addClass("show");
            $(".hamburgerNav-Icon-mobile").addClass("hide");
            $(".hamburgerNav-Icon-Close").removeClass('show').addClass('hide')
        }
        $("html").css("overflow-y", "");
        $(".modal-backdrop").removeClass("menu-top");
        $(".navigationbarcollectioncomponent").hide();
        $(".hamburger").removeClass('fa-times').addClass("fa-bars");
        $("#menu").show();
        $("#close").hide();
        $(".hamburgerNav").css("background-color", "#0f99d6");
        $(".hamburger").css("color", "white");
        $("#close").css("color", "white");
        $(".ham_menu").addClass("ham_menu-width").removeClass("ham_menu-incWidth ham_menu-secondWidth");
        $(".secondParent").css("display", "none");
        $('.menu-parent-wrapper .first-category-label a .view-all-text').remove();
    }
    $('.menu-lang-toggler').removeClass('sub-cat-open');
    $('.hamLink').removeClass('active-ham-link');
});

//Optimizely Product Recommendation Carousel
function optimizelyProductCarousel() {
	//For showing single product
	if ($(".optimizely-recomm-wrapper").hasClass("single-card")) {
		$(".single-card #owl-optRecommendedProducts").owlCarousel({
			autoPlay : false,
			rewindNav : false,// Set AutoPlay to 3
			items : 1,
			navigation : true,
			lazyLoad:true,
			responsive : {
				0 : {
					items : 1,
					loop : ($(this).children('.item').length > 1)
				},
			}
		});
	}

	var itemsToShow;
	if (RAC.coreDataLayer.pageType === 'product-category' || RAC.coreDataLayer.pageType === 'search') {
		itemsToShow = 2;
	} else {
		itemsToShow = 4;
	}
	$(".optimizely-recomm-wrapper #owl-optRecommendedProducts").owlCarousel({
		autoPlay : false,
		rewindNav : false,// Set AutoPlay to 3
		items : 4,
		navigation : true,
		lazyLoad:true,
		responsive : {
			0 : {
				items : 1,
				loop : ($(this).children('.item').length > 1)
			},
			768 : {
				items : 2,
				loop : ($(this).children('.item').length > 2)
			},
			1000 : {
				items : itemsToShow,
				loop : ($(this).children('.item').length > 4)
			}
		}
	});

	if ($(window).width() <= 768) {
		$("#owl-optRecommendedProducts .item:nth-child(-n+1)").css("display", "block");
	}
	if (($(window).width() == 769) && ($(window).width() < 1200)) {
		$("#owl-optRecommendedProducts .item:nth-child(-n+3)").css("display", "block");
	}
	$(".optimizely-recomm-wrapper .product-interest-wrapper").css("background", "none");
	$("#owl-optRecommendedProducts").css("visibility", "visible");
	$("#owl-optRecommendedProducts .item:nth-child(-n+4)").css("display", "block");
	$(".optimizely-recomm-wrapper .owl-next").html('<img class="owl-carouselArrowsRight" height="29" width="29" alt="carouselRightArrow" src="/_ui/responsive/common/images/RAC-iconImages/Nav-arrow-right.svg">');
	$(".optimizely-recomm-wrapper .owl-prev").html('<img class="owl-carouselArrowsLeft" height="29" width="29" alt="carouselLeftArrow" src="/_ui/responsive/common/images/RAC-iconImages/Nav-arrow-left.svg">');
}

//Optimizely Callback Variable
const recommendationComponentPages = $('#optimizelyCommonRecsTrackingPages').attr('data-value').split(','); //To identify pages with RAC.coreDataLayer.pageType or with any unique class on body
const currentPageType = RAC.coreDataLayer.pageType;
if (recommendationComponentPages.includes(currentPageType) || recommendationComponentPages.some(className => $('body').hasClass(className))) {
	let acceptedRecsWidgets = ['homeWidget', 'productAlternativesWidget', 'basketWidget'];
	//Add RAC.coreDataLayer.pageType or any unique class from body to only send tracking information for specific pages
	let trackingPagesOnly = $('#optimizelyCommonTrackingOnlyPages').attr('data-value').split(','); //Adding super category page for tacking only
	let recommendationFlag = $('#isOptimizelyDataJSONEnabled').attr('data-value');
	let optData = $('#optimizelyData').attr('data-value');
	optData = JSON.parse(optData);
	var PeeriusCallbacks= {
		track : optData,
		smartRecs  : function(recsResponse) {
			consoleLog(recsResponse);
			if (!trackingPagesOnly.includes(currentPageType) && !trackingPagesOnly.some(className => $('body').hasClass(className))) {
				if (isTrue(recommendationFlag)) {
					if($('.optimizely-recomm-wrapper').length > 0) {
						let recsCookieValue = getCookieValue($('#optimizelySortTypeCookie').attr('data-value'));
						let productRecs = {
							"recommendation": [],
							"sortType": recsCookieValue
						};
						//Checking widget position key.
						for (const recsObject of recsResponse) {
							const recWidget = recsObject.position;
							if (acceptedRecsWidgets.includes(recWidget)) {
								const recs = recsObject.recs;
								for (const rec of recs) {
									let recomId = rec.id.toString();
									productRecs.recommendation.push({"refCode": rec.refCode,"id": recomId});
								}
							}
						}
						productRecs = JSON.stringify(productRecs);
						$.ajax({
							url: "/filter/productRecommendation" ,
							type: "POST",
							contentType: 'application/json',
							data: productRecs,
							success: function(response) {
								let responeParser = new DOMParser();
								let responseDoc = responeParser.parseFromString(response, 'text/html');
								let recsProductCount = responseDoc.querySelector('#product-recs-count').value;
								if (recsProductCount > 0) {
									$('.optimizely-recomm-wrapper').append(response);
									$('.optimizely-recomm-wrapper').removeClass('hide');
									optimizelyProductCarousel();
								}
							}, error: function(xhr, status, error) {
								console.log("AJAX Error:", status, error);
							}
						});
					}
				}
			}
		},
		apiVersion : "v1_4"
	}
}

function getCookieValue(cookieName) {
  const cookies = document.cookie.split('; ');
	for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === cookieName) {
        return decodeURIComponent(value);
    }
  }
  return null;
}

//Tracking user clicks on recommended product
$(document).on('click', '.optimizely-recomm-wrapper .featuredProdItemWrapper', function() {
	let recomProdId = $(this).attr('data-recom-id');
	Peerius.smartRecsClick(recomProdId);
	consoleLog(recomProdId);
});